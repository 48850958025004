import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Cparking from "../Upages/Cparking";

const Parking = () => {

    return (
        <>
          <Navbar />
         <Cparking/>
         
         <Footer/>

        </>
    );
}
export default Parking;