
import Navbar from "../components/Navbar";
import Coffer from "../components/Coffer";
import Footer from "../components/Footer";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Offerpage from "../components/Offerpage";

function Offer() {
  const location = useLocation();

  useEffect(() => {
    const scrollToHash = () => {
      const id = location.hash.substr(1); 
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
    scrollToHash();
    window.addEventListener('hashchange', scrollToHash);
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };
  }, [location]);

    return(
      <>
 


 <style dangerouslySetInnerHTML={{ __html: " .navbar {background-color: #babebf;} .hero-section{height: auto;}" }} />
    <Navbar />

    <div className="container hero-section">
    
      <Coffer />
      <Offerpage />
    
    
     
    
  </div>

   <Footer />
     
     
      </>

    );
}

export default Offer;