import React from "react"
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";

const Ustaff = () => {

  return (

    <>
    
    <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
            <div className="col-lg-9 col-12">
           
     
      
</div>
</div>

<Footer />
    </>
  );
}

export default Ustaff;
