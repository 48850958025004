import React from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";


function Pending() {
return(

    <>
     <style dangerouslySetInnerHTML={{ __html: "body{background: rgb(63,94,251); background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);\n        }\n        .navbar {background-color: #babebf;} .hero-section{height: auto;}" }} />
    <Navbar />
    <div className="container hero-section">
  <div className="row justify-content-center">
    <div className="col-lg-5 col-12 ">
      <div className="box  p-4" style={{ backgroundColor: "rgb(255, 255, 255)" }} >
     
        <img src="../assets/images/pending.jpg" alt="" className="w-100 " />
        <form action="">
          <p className="text-center mb-2">
            <b>Account approval pending</b>
          </p>
          <p className="text-center">
           
            Your account will be activated once your society admin approves it.
            Approvals typically take 1-2 days. If it's taking longer, please
            remind your admin using the button below.
          </p>
          <div className="row">
            <div className="col-lg-6 col-7 text-center">
              <span  className=" btn btn-success w-100"> Send a Reminder
                </span> 
            </div>
            <div className="col-lg-6 col-5 text-center">
              <Link  to='/login' className="btn btn-success w-100">
                + Add Flats
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

    </>
)
}

export default Pending;