import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Csociety from "../Upages/Csociety";

const Swimming = () =>{

    return(
        <>
      <Navbar />
      
         <Csociety />
    <Footer/>
        
        </>
    );
}
export default Swimming;