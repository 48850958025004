import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Cgym from "../Upages/Cgym";

const Gym = () =>{

    return(
        <>
        <Navbar />
   
      
         <Cgym />
            <Footer/>
        </>
    );
}
export default Gym;