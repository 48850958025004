
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Select from "../components/Select";
import Code from "../components/Code";
import { Link } from "react-router-dom";

function Uprofile() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A' };

  return (

    <>

      <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
        <div className="row">
            <div className="col-lg-3 col-6 bg-primary" id="alink">
              <Link to="/myprofile" className="text-white">Update Your Profile</Link>
            </div>
            <div className="col-lg-3 col-6 bg-success" id="alink">
            <Link to="/change-password" className="text-white">Change Password</Link>
            </div>
           
          </div>
          <div className="row mt-2 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant ">Update Your Profile </h5> <hr />
                </div>

              </div>
              <div className="row">
                <div className="col-lg-6 col-12 mt-3  form-group">

                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label className="mt-2"><b>I am an/a</b> </label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role} disabled />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-12 mt-3  form-group">

                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label htmlFor="account-fn"> <b>Name</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input className="w-100 form-control text-uppercase" type="text"
                        name="name" value={userDetails.name} disabled />

                    </div>

                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-3  form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Phone</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">

                      <div className="row">
                        <div className="col-4">
                          <Code />
                        </div>
                        <div className="col-8">
                          <input className="w-100 form-control text-uppercase" type="text"
                            name="phone" value={userDetails.phone} disabled />
                        </div>
                      </div>


                    </div>

                  </div>

                </div>
                <div className="col-lg-6 col-12 mt-3  form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control text-uppercase"
                        type="text"
                        id="account-fn"
                        placeholder="Enter Email"
                        value={userDetails.email} disabled
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 mt-3  form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-3 ">
                      <label htmlFor="account-fn"> <b>Flat Number</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control text-uppercase"
                        type="text"
                        id="account-fn"
                        placeholder="Enter Flat"
                        value={userDetails.flat} disabled
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 mt-3  form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Tower</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control text-uppercase"
                        type="text"
                        id="account-fn"
                        value={userDetails.tower} disabled
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 mt-3  form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-3 ">
                      <label htmlFor="account-fn"> <b>Parking No.</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control text-uppercase"
                        type="text"
                        id="account-fn"
                        placeholder="Enter Parking No."
                        required=""
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Address Proof</b> </label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control text-uppercase"
                        type="file"
                        id="account-fn"
                        placeholder="Enter Breed"
                        required=""
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-2">
                      <label htmlFor> <b>Parking Sticker issued</b>
                        <span className="text-danger">*</span></label>
                    </div>
                   <Select />
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-2">
                      <label htmlFor> <b>RFID TAG [Boom Barrier]</b>
                        <span className="text-danger">*</span></label>
                    </div>

                    <Select />

                  </div>

                </div>
                <div className="col-lg-6 col-12 mt-3 mb-3 form-group">


                  <div className="row">
                    <div className="col-lg-6 col-12 mt-2">
                      <label  htmlFor> <b>Access Card</b>
                        <span className="text-danger">*</span></label>
                    </div>

                    <Select />

                  </div>

                </div>
               
                
              </div>
              <div className="row justify-content-end">
                <div className="col-lg-3 col-12  mb-3">
                  <input className="bg-primary text-white form-control text-uppercase me-5  " type="submit" defaultValue="Submit" />
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>

      <Footer />
    </>
  );
}

export default Uprofile;
