import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Cduties from "../Upages/Cduties";


const Duties = () =>{

    return(
        <>
  
     <Navbar /> 
      <Cduties />
      <Footer />
        
        </>
    );
}
export default Duties;