import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Csociety from "../Upages/Csociety";


const Society = () =>{

    return(
        <>
           <Navbar />
   
      <Csociety />
            <Footer/>
        </>
    );
}
export default Society;