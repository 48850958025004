import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Claw from "../Upages/Claw";


const Law = () => {

    return(
        <>
            <Navbar />
           <Claw />
            <Footer/>
    </>
    );
}
export default Law;



