import React, { useState } from 'react';
import Unavbar from '../components/Unavbar';
import Unav from '../components/Unav';
import { Link } from 'react-router-dom';
const Bill = () => {
  const [activeSection, setActiveSection] = useState(null);

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  return (
    <>
    <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
    
      <div className="row">

      <div className="col-lg-3 col-6 bg-primary" id="alink">
          <Link type='button' className="text-white" onClick={() => handleButtonClick('section1')}>Mobile & Broadband</Link>
        </div>
        <div className="col-lg-2 col-6 bg-danger" id="alink">
        <Link type='button' className="text-white" onClick={() => handleButtonClick('section2')}>Gas Pipeline</Link>
        </div>
        <div className="col-lg-3 col-6 bg-success" id="alink">
        <Link type='button' className="text-white" onClick={() => handleButtonClick('section3')}>LIC/Insurance</Link>
        </div>
        <div className="col-lg-2 col-6 bg-warning" id="alink">
        <Link type='button' className="text-white" onClick={() => handleButtonClick('section4')}>Credit Card</Link>
        </div>
        <div className="col-lg-2 col-6 bg-Secondary" id="alink">
        <Link type='button' className="text-white" onClick={() => handleButtonClick('section5')}>Loan EMI</Link>
        </div>
      </div>


      <div className="row mt-2 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant">Bill Payment</h5>
                  <hr />
                </div>
                <div className="mt-4">
        {activeSection === 'section1' && (
          <div className="section">
            <h4>Mobile & Broadband</h4>
           
          </div>
        )}
        {activeSection === 'section2' && (
          <div className="section">
            <h4>Gas Pipeline</h4>
           
          </div>
        )}
        {activeSection === 'section3' && (
          <div className="section">
            <h4>LIC/Insurance </h4>
           
          </div>
        )}
        {activeSection === 'section4' && (
          <div className="section">
            <h4>Credit Card</h4>
          
          </div>
        )}
          {activeSection === 'section5' && (
          <div className="section">
            <h4>Loan EMI</h4>
          
          </div>
        )}
      </div>

                </div>
                </div>
                </div>
     
    </div>
    </div>
    </>
  );
};

export default Bill;
