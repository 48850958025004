import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Select from "../components/Select";
import Code from "../components/Code";
import Applyform from "../components/Applyform"; 

function Uparking() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A' };
  return (

    <>

      <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">

           <Applyform />

          <div className="row mt-3 me-2 ms-1">


            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12 mb-2">
                  <h5 className="radiant mt-2">Apply Parking Sticker</h5> <hr />
                </div>
                <div className="col-4">
        <label className="mt-1 mb-2" htmlFor> <b> Do you have an old Parking Sticker ?</b> <span className="text-danger">*</span></label>
        </div>  
          <Select />
              
              </div>
              <div className="row">
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label className="mt-2"><b>I am an/a</b> </label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Name</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="w-100 form-control text-uppercase" type="text"
                          name="name" value={userDetails.name} disabled />

                      </div>

                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2 ">
                        <label htmlFor="account-fn"> <b>Phone</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">

                        <div className="row">
                          <div className="col-4">
                            <Code />
                          </div>
                          <div className="col-8">
                            <input className="w-100 form-control text-uppercase" type="text"
                              name="phone" value={userDetails.phone} disabled />
                          </div>
                        </div>


                      </div>

                    </div>

                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2 ">
                        <label htmlFor="account-fn"> <b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control text-uppercase"
                          type="text"
                          id="account-fn"
                          placeholder="Enter Email"
                          value={userDetails.email} disabled
                        />

                      </div>

                    </div>



                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-3 ">
                        <label htmlFor="account-fn"> <b>Flat Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control text-uppercase"
                          type="text"
                          id="account-fn"
                          placeholder="Enter Flat"
                          value={userDetails.flat} disabled
                        />

                      </div>

                    </div>



                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2 ">
                        <label htmlFor="account-fn"> <b>Tower</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control text-uppercase"
                          type="text"
                          id="account-fn"
                          value={userDetails.tower} disabled
                        />

                      </div>

                    </div>

                   
                  </div>
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Parking Number</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control"
                        type="text"
                        id="account-fn"
                        placeholder="Enter Parking Number"
                        required=""
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b> Vehicle No </b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control"
                        type="text"
                        id="account-fn"
                        placeholder="Enter  Vehicle No "
                        required=""
                      />

                    </div>


                  </div>



                </div>
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Vehicle Model</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control"
                        type="text"
                        id="account-fn"
                        placeholder="Enter Vehicle Model"
                        required=""
                      />

                    </div>


                  </div>

                </div>
                
                <div className="col-lg-6 col-12 mt-3 form-group">
                <div className="row">
                    <div className="col-lg-5 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Driving Licence Number </b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-7 col-12 mt-2">
                      <input
                        className="form-control"
                        type="text"
                        id="account-fn"
                        placeholder="Enter Driving Licence Number "
                        required=""
                      />

                    </div>


                  </div>
                               
                   
                    </div>

                    <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Attachment
                        </b> <span className="text-danger">*</span></label>
                        </div>

                        <div className="col-lg-8 col-12 mt-2">
                        <input
                        className="form-control w-100"
                        type="file"
                        id="account-fn"
                        placeholder="Enter Attachment"
                        required=""
                      />
                        </div>
                  

                </div>
               

                   </div>

                   <div className="col-lg-6 col-12 mt-3 mb-3 form-group">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <label htmlFor="account-fn"> <b>Remark:</b> </label>
                    </div>
                    <div className="col-12 mt-1">
                    <textarea className="form-control" id="remarks" name="remarks" rows={3} defaultValue={""} />

                    </div>


                  </div>




                </div>
             
            </div>

            <div className="row mb-3">
                    <div className="col-lg-3 col-12 ">
                    <input className="bg-primary text-white form-control" type="submit" value="Submit" />
                    </div>
                  </div>
          </div>


          </div>
          </div>

        </div>
    

      <Footer />
    </>
  );
}

export default Uparking;
