import React from "react";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Cpet from "../Upages/Cpet";

const Pet = () =>{

    return(
        <>
      <Navbar />
     
<Cpet />
        <Footer/>
        </>
    );
}
export default Pet;