import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Celection from "../Upages/Celection";

const Election = () =>{

    return(
        <>
        <Navbar />

       <Celection />
 
        <Footer/>
        </>
    );
}
export default Election;