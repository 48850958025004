import React from "react"
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Code from "../components/Code";
import Applyfrom from "../components/Applyform";

function Uhall() {

  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A' };
  return (

    <>

      <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
    
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">

    <Applyfrom />
          <div className="row mt-3 me-2 ms-1">


            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant mt-2">Book Resources & Common Place</h5> <hr />
                </div>

                
              </div>
              <div className="row">
                <div className="col-lg-6 col-12 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label htmlFor="account-fn"> <b>Name</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                       <input className="w-100 form-control text-uppercase" type="text"
                          name="name" value={userDetails.name} disabled />
                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Phone</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                    <div className="row">
                                            <div className="col-4">
                                                <Code />
                                            </div>
                                            <div className="col-8">
                                            <input className="w-100 form-control text-uppercase" type="text"
                          name="phone" value={userDetails.phone} disabled />
                                            </div>
                                        </div>

                    </div>

                  </div>



                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-12 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                    <input className="w-100 form-control text-uppercase" type="text"
                          name="email" value={userDetails.email} disabled />

                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-3 ">
                      <label htmlFor="account-fn"> <b>GST Number</b> </label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control"
                        type="text"
                        id="account-fn"
                        placeholder="Enter GST Number"
                        required=""
                      />

                    </div>

                  </div>



                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-12 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Company Name</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control"
                        type="text"
                        id="account-fn"
                        placeholder="Enter Company Name"
                        required=""
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-6 col-12 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-3 ">
                      <label htmlFor="account-fn"> <b>Business Type</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control"
                        type="text"
                        id="account-fn"
                        placeholder="Enter Business Type"
                        required=""
                      />

                    </div>

                  </div>



                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6 col-12 form-group">
                  <div className="row">
                    <div className="col-lg-12 col-12 mt-3 ">
                      <label htmlFor="account-fn"> <b>Number Of Booking Days</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-6 col-12 mt-2">
                      <label htmlFor="">TO:
                        <input
                          className="form-control"
                          type="datetime-local"

                        />
                      </label>
                    </div>
                    <div className="col-lg-6 col-12 mt-2">

                      <label htmlFor="">Form:
                        <input
                          className="form-control"
                          type="datetime-local"

                        />
                      </label>

                    </div>

                  </div>


                </div>
                <div className="col-lg-6 col-12 form-group">


                  <label htmlFor="account-fn"> <b>Address:</b> <span className="text-danger">*</span></label>
                  <div className="row">

                    <div className="col-12 mt-3">
                      <textarea className="form-control" id="remarks" name="remarks" rows={3} defaultValue={""} />
                    </div>
                  </div>

                </div>

              </div>


              <div className="row mt-3 mb-3">
                <div className="col-lg-6 col-12">
                  <label htmlFor="account-fn"> <b>Remarks:</b> <span className="text-danger">*</span></label>
                  <div className="row">

                    <div className="col-12 mt-3">
                      <textarea className="form-control" id="remarks" name="remarks" rows={3} defaultValue={""} />
                    </div>
                  </div>

                </div>


              </div>
              <div className="row justify-content-end">
                <div className="col-lg-3 col-12  mb-3">
                  <input className="bg-primary text-white form-control text-uppercase me-5  " type="submit" defaultValue="Submit" />
                </div>
            </div>
          </div>




        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}

export default Uhall;
