
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import { Link } from "react-router-dom";

function Updatepwd () {


  return (

    <>

      <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
        <div className="row">
            <div className="col-lg-3 col-6 bg-primary" id="alink">
              <Link to="/myprofile" className="text-white">Update Your Profile</Link>
            </div>
            <div className="col-lg-3 col-6 bg-success" id="alink">
              <Link to="/change-password" className="text-white">Change Password</Link>
            </div>
           
          </div>
          <div className="row mt-2 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant ">Change Password </h5> <hr />
                </div>

              </div>
              <div className="row">
                <div className="col-7">
              <div className="col-lg-12 col-12 mt-3  form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>Old Password</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control"
                        type="password"
                        id="account-fn"
                        placeholder="Old Password"
                        required=""
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-12 col-12 mt-3  form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2 ">
                      <label htmlFor="account-fn"> <b>New Password </b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input
                        className="form-control"
                        type="password"
                        id="account-fn"
                        placeholder="New Password"
                        required=""
                      />

                    </div>

                  </div>



                </div>
                <div className="col-lg-12 col-12 mt-3  mb-4 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label htmlFor="account-fn"><b>
                        Confirm Password</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">

                      <input
                        className="form-control"
                        type="password"
                        id="account-fn"
                        placeholder="Enter Confirm Password"
                        required=""
                      />

                    </div>

                  </div>



                </div>
                <div className="row justify-content-end">
                <div className="col-lg-3 col-12 mb-3">
                  <input className="bg-primary text-white form-control me-5  " type="submit" defaultValue="Submit" />
                </div>
              </div>
              </div>
            
            </div>
            </div>
          </div>



        </div>
      </div>

      <Footer />
    </>
  );
}

export default Updatepwd;
